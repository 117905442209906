<template>
  <a-radio-group v-model="data.edit_jump_type" @change="handlerChange">
    <div>
      <a-radio :style="radioStyle" :value="0">无跳转</a-radio>
    </div>
    <div class="flex" v-if="supportWeb">
      <a-radio :style="radioStyle" :value="1">页面链接</a-radio>
      <a-input style="flex:1;" placeholder="https:// 如公众号文章链接"
        v-if="data.edit_jump_type == 1"
        v-model="data.edit_jump_data.url"
      />
    </div>
    <div class="flex" v-if="supportShopGoods">
      <a-radio :style="radioStyle" :value="2">门店商品</a-radio>
      <shop-goods-select :shopType="shopType" :data="data" v-if="data.edit_jump_type == 2" />
    </div>
    <div class="flex" v-if="supportMallGoods">
      <a-radio :style="radioStyle" :value="3">商城商品</a-radio>
      <!-- <mall-goods-select :data="data" v-if="data.edit_jump_type == 3" /> -->
      <mall-good-detail-select :data="data" v-if="data.edit_jump_type == 3" />
    </div>
    <div class="flex" v-if="supportMinipage">
      <a-radio :style="radioStyle" :value="4">小程序页面</a-radio>
      <a-select class="mr-2" placeholder="请选择页面" style="flex:1;max-width:300px;"
          v-if="data.edit_jump_type == 4"
          v-model="data.edit_jump_data.url">
        <a-select-option v-for="(item, index) in minigramPageList"
            :key="index" :value="item.path">{{ item.name }}</a-select-option>
      </a-select>
    </div>
    <div class="flex" v-if="supportMinicoupon">
      <a-radio :style="radioStyle" :value="5">优惠券活动</a-radio>
      <a-select class="mr-2" placeholder="请选择优惠券活动" style="flex:1;max-width:300px;"
          v-if="data.edit_jump_type == 5"
          v-model="data.edit_jump_data.coupon_event_id">
        <a-select-option v-for="(item, index) in couponEventList"
            :key="index" :value="item.id">{{ item.name }}</a-select-option>
      </a-select>
    </div>
  </a-radio-group>
</template>

<script>
import { minigramPageList } from "@/utils/core"
import ShopGoodsSelect from "./shop-goods-select.vue"
// import MallGoodsSelect from "./mall-goods-select.vue"
import MallGoodDetailSelect from "./mall-good-detail-select.vue"
import { getList } from "@/api/activity/shop-coupon-event.js"

export default {
  components: { 
    ShopGoodsSelect,
    // MallGoodsSelect, 
    MallGoodDetailSelect 
  },
  props: {
    data: {
      type: Object,
    },
    type: {
      type: [Number, String],
      default: "0", // 1 网页、2 门店商品、3 商城商品、4 小程序其他链接、5 小程序优惠券
    },
    shopType: {
      type: [Number, String],
      default: "0", // 0全部 1常规 2好茶
    },
  },
  data() {
    return {
      minigramPageList,
      couponEventList: [],

      supportWeb: false,
      supportShopGoods: false,
      supportMallGoods: false,
      supportMinipage: false,
      supportMinicoupon: false,

      radioStyle: {
        // display: "block",
        height: "30px",
        lineHeight: "30px",
      },
    }
  },
  mounted() {
    const type = this.type || "0"
    this.supportWeb      = /[01]/.test(type)
    this.supportShopGoods   = /2/.test(type)
    this.supportMallGoods   = /3/.test(type)
    this.supportMinipage    = /4/.test(type)
    this.supportMinicoupon  = /5/.test(type)

    if(this.data){
      // 兼容特殊情况（比如接口bug返回的edit_jump_data为空、页面读属性就报错了）
      if(!this.data.edit_jump_data){
        this.data.edit_jump_data = {
          url: undefined,
          type_id: undefined,
          son_type_id: undefined,
          goods_id: undefined,
        }
        this.$forceUpdate()
      }
    }

    if(this.supportMinicoupon){
      this.handlerInitCouponEvent()
    }
  },
  methods: {
    handlerChange() {
      this.data.edit_jump_data = {
        url: undefined,
        type_id: undefined,
        son_type_id: undefined,
        goods_id: undefined,
        coupon_event_id: undefined
      }
      // if (e.target.value === 0) {
      //   this.data.edit_jump_data = []
      // } else if (e.target.value === 3) {
      //   if (Array.isArray(this.data.edit_jump_data)) {
      //     this.data.edit_jump_data = {
      //       url: "",
      //       type_id: "",
      //       son_type_id: "",
      //       goods_id: "",
      //     }
      //   }
      // } else if (e.target.value == 1) {
      //   if (Array.isArray(this.data.edit_jump_data)) {
      //     this.data.edit_jump_data = {
      //       url: "",
      //     }
      //   }
      // } else if (e.target.value === 2) {
      //   if (Array.isArray(this.data.edit_jump_data)) {
      //     this.data.edit_jump_data = {
      //       type_id: "",
      //       son_type_id: "",
      //       goods_id: "",
      //     }
      //   }
      // } else if (e.target.value === 4) {
      //   if (Array.isArray(this.data.edit_jump_data)) {
      //     this.data.edit_jump_data = {
      //       url: "",
      //     }
      //   }
      // }
    },

    async handlerInitCouponEvent(){
      // couponEventList
      const { data, code } = await getList({
        type: 1, // 线上活动
        name: "",
        name_interior: "",
        event_time_end: "",
        event_time_start: "",
        date_type: 2,
        page: 1,
        page_count: 20,
      })
      if (code === 0) {
        this.couponEventList = data.list
      }
    },
  },
}
</script>

<style>
</style>