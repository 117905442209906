<template>
  <div class="flex">
    <a-select
      style="width: 300px"
      v-model="data.edit_jump_data.goods_id"
      :options="goodsList"
      placeholder="请选择商品"
    ></a-select>
  </div>
</template>

<script>
// import { getMallTypeList, getMallGoodsList } from "@/api/mall"
import { getGoodsList } from "@/api/goods"

export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      typeList: [],
      goodsList: [],
    }
  },
  mounted() {
    this.initGoodsData2()
  },
  methods: {
    
    async initGoodsData2() {
      const { data, code } = await getGoodsList({ 
        status: 1,
        sale_location: 1,
        sale_status: 1,
        page_count: 100,
      })
      if (code === 0) {
        this.goodsList = data.list.map((item) => {
          return {
            label: item.goods_name,
            value: item.goods_id,
          }
        })
      }
    },
  },
}
</script>

<style>
</style>